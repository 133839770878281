<template>
  <b-container fluid>
    <ValidationObserver ref="FormSettingsObserver" v-slot="{ invalid }">
      <form>
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">{{$t("FormPreferences")}}</h4>
          </template>
          <template v-slot:body>
            <b-row>
              <b-form-group class="col-md-5" style="text-align:right" :label="$t('ShowClarificationText')" label-for="showClarificationText"></b-form-group>
              <b-form-group class="col-md-7">
                <b-form-checkbox v-model="model.showClarificationText" name="showClarificationText" switch size="lg"></b-form-checkbox>
              </b-form-group>
            </b-row>
            &nbsp;
            <b-row>
              <b-form-group class="col-md-5" style="text-align: right" :label="$t('ClarificationTextLink')" label-for="clarificationTextLink">
              </b-form-group>
              <b-textarea class="col-md-7" v-model="model.clarificationTextLink" :disabled="!(model.showClarificationText)"> </b-textarea>
            </b-row>
            &nbsp;
            <b-row>
              <b-form-group class="col-md-5" style="text-align: right" label-for="clarificationText" :label="$t('ClarificationText')"></b-form-group>
              <b-textarea class="col-md-7" v-model="model.clarificationText" :disabled="!(model.showClarificationText)"></b-textarea>
            </b-row>
          </template>
        </iq-card>

        <iq-card>
          <template v-slot:body>
            <b-row>
              <b-form-group class="col-md-5" style="text-align:right" :label="$t('ShowExplicitConsentText')" label-for="showExplicitConsentText"></b-form-group>
              <b-form-group class="col-md-7">
                <b-form-checkbox v-model="model.showExplicitConsentText" name="showExplicitConsentText" switch size="lg"></b-form-checkbox>
              </b-form-group>
            </b-row>
            &nbsp;
            <b-row>
              <b-form-group class="col-md-5" style="text-align: right" :label="$t('ExplicitConsentLink')" label-for="explicitConsentLink">
              </b-form-group>
              <b-textarea class="col-md-7" v-model="model.explicitConsentLink" :disabled="!(model.showExplicitConsentText)"> </b-textarea>
            </b-row>
            &nbsp;
            <b-row>
              <b-form-group class="col-md-5" style="text-align: right" label-for="explicitConsentText" :label="$t('ExplicitConsentText')"></b-form-group>
              <b-textarea class="col-md-7" v-model="model.explicitConsentText" :disabled="!(model.showExplicitConsentText)"></b-textarea>
            </b-row>
          </template>
        </iq-card>
        <modal-footer-button @submit="submitFormSettings"></modal-footer-button>
      </form>
    </ValidationObserver>
  </b-container>
</template>
<script>

  import managementServices from '../../../services/management';
  import ModalFooterButton from '../../../components/shared/ModalFooterButton';
  export default {
    name: 'FormSettings',
    components: {
      ModalFooterButton
    },
    data() {
      return {
        model: {
          showClarificationText: false,
          clarificationText: '',
          clarificationTextLink: '',

          showExplicitConsentText: false,
          explicitConsentText: '',
          explicitConsentLink: '',
        },
      }
    },
    methods: {
      getFormSettings() {
        managementServices.getFormSettings().then((response) => {
          if (response) {
            this.model = response;
          }
        })
      },
      async submitFormSettings() {
        const isValid = await this.$refs.FormSettingsObserver.validate();
        if ((!isValid || this.model.showExplicitConsentText && (!this.model.explicitConsentLink && !this.model.explicitConsentText)) || (this.model.showClarificationText && (!this.model.clarificationText && !this.model.clarificationTextLink))) {
          this.$toastr.error(this.$t('ValidationMessage.EmptyAndValidateError'));
          return;
        }
        managementServices.submitFormSettings(this.model).then((result) => {
          if (result) {
            this.$emit('closeModal');
            this.$toastr.success(this.$t('Success'));

          } else {
            this.$toastr.error(this.$t('Error'));
          }
        })
      },
    },
    mounted() {
      this.getFormSettings();
    }
  }
</script>

