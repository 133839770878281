import axios from './index';

export default {
  submitCustomerSettings(model) {
    return axios.post('/management/updateCustomerSettings', model)
      .then(response => {
        return response.data;
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  submitGeneralSettings(model) {
    return axios.post('/management/updateGeneralSettings', model)
      .then(response => {
        return response.data;
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  getGeneralSettings() {
    return axios.get('/management/getGeneralSettings')
      .then(response => {
        return response.data;
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  getLogo() {
    return axios.get('/management/getLogo')
      .then(response => {
        return response.data;
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  submitLogo(formData) {
    return axios.post('/management/uploadLogo', formData)
      .then(response => {
        return response.data;
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  removeLogo() {
    return axios.post('/management/RemoveLogo')
      .then(response => {
        return response.data;
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  getFormSettings() {
    return axios.get('/management/GetFormSettings')
      .then(response => {
        return response.data;
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  submitFormSettings(model) {
    return axios.post('/management/UpdateFormSettings', model)
      .then(response => {
        return response.data;
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  getDocplannerSettings() {
    return axios.get('/api/DocplannerSettings/Get')
      .then(response => {
        return response.data;
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  submitDocplannerSettings(model) {
    return axios.post('/api/DocplannerSettings/Upsert', model)
      .then(response => {
        return response.data;
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
}
